@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  margin: 0;
  font-family: 'Lato', sans-serif;
  scroll-behavior: smooth;
}

@font-face {
  font-family: spicy;
  src: url(./SpicyRice-Regular.ttf);
}

body::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #24485B;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ff7d08;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
}