@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(/static/media/SpicyRice-Regular.783e8aef.ttf)  format('truetype'), 
}


* {
  margin: 0;
  font-family: 'Lato', sans-serif;
  scroll-behavior: smooth;
}

@font-face {
  font-family: spicy;
  src: url(/static/media/SpicyRice-Regular.783e8aef.ttf);
}

body::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #24485B;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ff7d08;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
}
